import Axios from 'axios';
import VueCookies from 'vue-cookies';
import { createToast } from 'mosha-vue-toastify';
import router from '../router';

const APIBaseURL = process.env.VUE_APP_BACKEND_URL;

const adapter = Axios.create({
  baseURL: APIBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const badgeGeneratorAdapter = Axios.create({
  baseURL: process.env.VUE_APP_BADGE_GENERATOR_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_BADGE_GENERATOR_KEY,
  },
});

adapter.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

badgeGeneratorAdapter.interceptors.request.use(
  (config) => {
    config.headers['Token'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function eachRecursive(obj) {
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k]);
    else if (obj[k] == null) obj[k] = '';
  }
}

async function updateIssuerV2(body, file) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.put('/managers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function createIssuerV2(body, file) {
  const formData = new FormData();
  formData.append('file', file);
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.post('/managers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function deleteIssuerV2() {
  return adapter.delete('/managers');
}

async function getIssuerV2() {
  return adapter.get('/managers');
}

async function getIssuerTemplatesV2() {
  return adapter.get('/managers/templates');
}

async function getIssuerTypeTemplatesV2(type) {
  return adapter.get(`/managers/templates/type/${type}`);
}

async function getIssuerTemplateV2(id) {
  return adapter.get(`/managers/templates/${id}`);
}

async function updateTemplateV2(body, file) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.put('/managers/templates', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function publishTemplateV2(id) {
  return adapter.post(`/managers/templates/publish/${id}`);
}

async function deleteTemplateV2(id) {
  return adapter.delete(`/managers/templates/${id}`);
}

async function createTemplateV2(body, file) {
  const formData = new FormData();
  formData.append('file', file);
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.post('/managers/templates', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export default {
  install(app) {
    adapter.interceptors.response.use(
      (response) => {
        eachRecursive(response.data);
        return response;
      },
      (error) => {
        if (error.reponse) {
          if (
            // error.response.status === 404 ||
            error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            VueCookies.remove('token');
            router.push('/');
          }
        }

        if (error.message === 'Network Error') {
          console.log("Can't reach server");
          createToast(`Can't reach server, please try again later`, {
            position: 'bottom-center',
            hideProgressBar: true,
            timeout: 3000,
            transition: 'slide',
            toastBackgroundColor: '#ff4545',
          });
          VueCookies.remove('token');
          router.push('/signin');
        }

        if (error.response.status === 403) {
          VueCookies.remove('token');
          router.push('/signin');
        }

        return Promise.reject(error);
      }
    );

    var evtSource = null;

    var api = {
      deleteTemplate(hash) {
        return adapter.delete('/operations/delete-template/' + hash);
      },
      queueOperation(type, data) {
        return adapter.post('/operations', {
          type,
          data,
        });
      },
      isIssuerRegistered(did) {
        return adapter.get('/managers/registered/' + did);
      },
      disableIssuer() {
        return adapter.put('/managers/disable');
      },
      getCitiesFromQuery(countryCode, query) {
        return adapter.get(`/cities/query/${countryCode}/${query}`);
      },
      getCityFromDetails(country, name) {
        return adapter.get(`/cities/details/${country}/${name}`);
      },
      createAuthChallenge(body) {
        return adapter.post('/auth/', body);
      },
      getAuthChallenge(challengeId) {
        return adapter.get(`/auth/${challengeId}`);
      },
      submitAuthVP(body) {
        return adapter.post('/auth/vp/', body);
      },
      sendIssuerPromoteRequest(body) {
        return adapter.post('/managers/promote', body);
      },
      createEmailVerification(body) {
        return adapter.post('/managers/verifications/email', body);
      },
      submitEmailValidation(body) {
        return adapter.put('/managers/verifications/email', body);
      },
      waitChallengeValidation(challenge, next) {
        evtSource = new EventSource(APIBaseURL + '/auth/sse/' + challenge, {
          xhrHeaders: {
            'Content-Type': 'text/event-stream',
            Connection: 'keep-alive',
          },
        });

        evtSource.addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data).data;
            console.log('Receive elements from stream : ', response);
            if (
              response.status == 'validated' &&
              response.challenge == challenge
            ) {
              console.log('Great, you did sign in');
              evtSource.close();
              return next(response);
            } else if (response.status == 'expired') {
              console.log('Challenge expired, you did not sign in');
              evtSource.close();
              return next(response);
            } else {
              next(response);
            }
          },
          false
        );

        evtSource.addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return evtSource;
      },
      closeChallengeValidation() {
        if (evtSource) evtSource.close();
      },
      waitOperationValidation(operationId, next) {
        evtSource = new EventSource(
          APIBaseURL + '/operations/sse/' + operationId,
          {
            xhrHeaders: {
              'Content-Type': 'text/event-stream',
              Connection: 'keep-alive',
            },
          }
        );

        evtSource.addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data);
            console.log('Receive elements from stream : ', response);
            next(response);
            if (['success', 'error', 'canceled'].includes(response.status)) {
              evtSource.close();
              return;
            }
          },

          false
        );

        evtSource.addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return evtSource;
      },
      createSessionBuilder(body) {
        return badgeGeneratorAdapter.post(`/auth/sessions/builders`, body);
      },
      getSessionBuilders(templateHash) {
        return badgeGeneratorAdapter.get(
          `/auth/sessions/builders/` + templateHash
        );
      },
      updateSessionBuilder(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions/builders`, body);
      },
      createSession(body) {
        return badgeGeneratorAdapter
          .post(`/auth/sessions`, body)
          .then((response) => {
            // Handle successful response
            return response.data;
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Error data:', error.response.data);
              console.error('Error status:', error.response.status);
              console.error('Error headers:', error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error request:', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error message:', error.message);
            }
            throw error; // Re-throw the error if you want to handle it further up the call stack
          });
      },
      retriggerExpiredSession(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions/resend`, body);
      },
      updateSession(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions`, body);
      },
      updateSessionNote(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions/note`, body);
      },
      sendOpenSessionByEmail(body) {
        return badgeGeneratorAdapter.post(`/auth/sessions/send`, body);
      },
      revokeAllSessionsForTemplate(templateHash) {
        return badgeGeneratorAdapter.post(`/auth/sessions/revoke`, {
          templateHash,
        });
      },
      updateIssuerV2,
      createIssuerV2,
      deleteIssuerV2,
      getIssuerV2,
      getIssuerTemplatesV2,
      getIssuerTypeTemplatesV2,
      getIssuerTemplateV2,
      updateTemplateV2,
      createTemplateV2,
      deleteTemplateV2,
      publishTemplateV2
    };
    app.provide('api', api);
  },
};

export {
  getIssuerV2,
  deleteIssuerV2,
  getIssuerTemplatesV2,
  getIssuerTypeTemplatesV2,
  getIssuerTemplateV2,
  updateTemplateV2,
  createTemplateV2,
  deleteTemplateV2,
  publishTemplateV2
};
