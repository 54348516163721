<template>
  <div>
    <div
      class="fixed w-modal-sm top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-10"
    >
      <div
        @click="closeModal"
        class="text-xl text-right text-gray-400 mr-2 cursor-pointer leading-none"
      >
        <icon name="cross" class="text-sm fill-gray-400" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import EventsBus from '../plugins/eventBus';
export default {
  setup(props, context) {
    const store = useStore();
    const { eventBus } = EventsBus();

    const typedData = ref(null);

    async function sign(type, signMethodId, data) {
      return new Promise(async (resolve, reject) => {
        let unwatch = null;
        try {
          const pingRequestId = await nostr.pingApp();
          let requestId = null;

          unwatch = watch(
            () => eventBus.value.get('nostr'),
            async (val) => {
              const [event] = val ?? [];
              const [eventType, eventData, eventSubType, eventRequestId] =
                event;

              if (eventType === 'user' && pingRequestId == eventRequestId) {
                switch (eventSubType) {
                  case 'Pong':
                    context.emit('ack');
                    requestId = await nostr.requestSign(type, {
                      signMethodId,
                      multiDataToSign: data.map((el) => {
                        return {
                          type: el.type,
                          dataToSign: el.message,
                        };
                      }),
                    });

                    break;
                }
              }
              if (eventType === 'user' && requestId == eventRequestId) {
                switch (eventSubType) {
                  case 'SignatureReject':
                    unwatch();
                    reject('Signature rejected');
                    break;
                  case 'Signature':
                    unwatch();
                    resolve(eventData.signatures);
                    break;
                }
              }
            }
          );
        } catch (error) {
          unwatch();
          reject(error);
        }
      });
    }

    function closeModal() {
      context.emit('close');
    }

    return {
      closeModal,
      sign,
      typedData,
    };
  },
};
</script>
