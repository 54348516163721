<template>
  <BasicSmModal
    v-if="!noGallery || (noGallery && showCropping)"
    @close="closeDisplay"
  >
    <div v-if="!showCropping">
      <p class="text-gray-500 mb-4 -my-4">
        {{ $t('imageHandler.choosePictureTitle') }}
      </p>
      <div
        @click="showCollectionPicker = !showCollectionPicker"
        class="flex cursor-pointer"
        click-outside-ignore-countrypicker
      >
        <p v-if="imageCollectionsId" class="text-sm font-medium mr-1">
          {{ $t(`collections.${imageCollectionsId}.name`) }}
        </p>
        <icon name="chevron-down" />
      </div>
      <div v-if="showCollectionPicker" class="relative">
        <div
          class="absolute w-[20rem] top-1 left-0 bg-white px-3 py-2 border rounded-xl shadow-md max-h-40 overflow-y-auto z-20"
          click-outside-ignore-countrypicker-target
          v-click-outside="
            () => {
              showCollectionPicker = false;
            }
          "
        >
          <p
            v-for="collectionId in Object.keys(imageCollections)"
            :key="collectionId"
            @click="
              () => {
                imageCollectionsId = collectionId;
                showCollectionPicker = false;
                scrollableDiv.scrollTop = 0;
              }
            "
            class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
          >
            {{ $t(`collections.${collectionId}.name`) }}
          </p>
        </div>
      </div>
      <div class="border-b mt-2 mb-3"></div>
      <div
        class="grid grid-cols-4 h-64 overflow-y-scroll overflow-x-hidden gap-0.5 p-2"
        ref="scrollableDiv"
      >
        <div
          v-for="image in imageCollections[imageCollectionsId]"
          class="w-full"
        >
          <img
            @click="
              currentImage != image
                ? (currentImage = image)
                : (currentImage = null)
            "
            :src="image.path"
            class="w-full cursor-pointer rounded-md"
            :class="{
              'border-blue-700 border-4': currentImage == image,
              'border-transparent border-4': currentImage != image,
            }"
          />
        </div>
      </div>

      <div class="flex items-center gap-3 mt-5">
        <div class="grow"></div>
        <button
          class="bg-white text-sm border shadow-sm w-max font-medium rounded-lg cursor-pointer py-1.5 px-2"
          @click="$refs.templatePictureInput.click()"
        >
          <icon name="image" class="mr-1.5" />{{
            $t('imageHandler.uploadButton')
          }}
        </button>

        <button
          @click="selectImage"
          class="bg-secondary text-white text-sm shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 ml-auto"
          :class="{
            'pointer-events-none opacity-50': !currentImageFile,
          }"
        >
          {{ $t('imageHandler.selectButton') }}
        </button>
      </div>
    </div>
    <div v-else>
      <p class="text-gray-500 mb-4 -my-4">
        {{ $t('imageHandler.cropTitle') }}
      </p>
      <cropper
        ref="cropper"
        class="cropper"
        :src="currentImagePreview"
        :stencil-props="{
          aspectRatio: aspectRatio
            ? aspectRatio
            : type == 'Membership'
            ? 85.6 / 53.98
            : 1 / 1,
        }"
      />
      <div v-if="!compressionLoading" class="flex items-center gap-3 mt-5">
        <button
          @click="selectCroppedImage"
          class="bg-secondary text-white text-sm shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 ml-auto"
        >
          {{ $t('imageHandler.confirmButton') }}
        </button>
      </div>
      <div v-else class="mt-5">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('imageHandler.compressAndCropTxt') }}<br />{{
            $t('imageHandler.compressAndCropTxtBis')
          }}
        </p>
      </div>
    </div>
    <input
      type="file"
      @change="importTemplatePictureInput"
      ref="templatePictureInput"
      class="hidden"
    />
  </BasicSmModal>
  <input
    v-else
    type="file"
    @change="importTemplatePictureInput"
    ref="templatePictureInput"
    class="hidden"
  />
</template>
<style scoped>
.vue-advanced-cropper::v-deep .vue-rectangle-stencil {
  display: flex !important;
  border-width: 1px !important;
  border-color: gray !important;
  /* box-shadow: 0 0 5px black, 0 0 5px black; */
}
.vue-advanced-cropper::v-deep .vue-advanced-cropper__foreground {
  opacity: 0;
}
</style>
<script>
import { ref, watch, inject, onMounted, onUnmounted } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import 'vue-advanced-cropper/dist/style.css';
import imageCompression from 'browser-image-compression';
import BasicSmModal from './BasicSmModal.vue';
import collections from '../../assets/badges/collections.json';

export default {
  props: {
    type: String,
    noGallery: Boolean,
    aspectRatio: Number,
  },
  setup(props, context) {
    const utils = inject('utils');
    const { t } = useI18n();

    const showCropping = ref(false);
    const showCollectionPicker = ref(false);
    const imageCollections = ref([]);
    const imageCollectionsId = ref(null);
    const currentImage = ref(null);
    const currentImageFile = ref(null);
    const currentImagePreview = ref(null);
    const currentImageMessage = ref(null);
    const compressionLoading = ref(false);
    const cropper = ref();
    const templatePictureInput = ref();
    const MAX_SIZE_IN_BYTES = 10 * 1024 * 1024;
    const scrollableDiv = ref();

    if (props.type) {
      // can't put variable for path inside context method
      let imageContext;
      let currentCollections;

      if (props.type == 'Community' || props.type == 'Basic') {
        imageContext = require.context(`/assets/badges/basic-community`, false);
        currentCollections = collections.reduce((array, collection) => {
          if (collection.scope == 'basic-community') array[collection.id] = [];
          return array;
        }, []);
      } else if (props.type == 'Membership') {
        imageContext = require.context(`/assets/badges/membership`, false);
        currentCollections = collections.reduce((array, collection) => {
          if (collection.scope == 'membership') array[collection.id] = [];
          return array;
        }, []);
      } else if (props.type == 'Participation' || props.type == 'Ticket') {
        imageContext = require.context(`/assets/badges/participation`, false);
        currentCollections = collections.reduce((array, collection) => {
          if (collection.scope == 'participation') array[collection.id] = [];
          return array;
        }, []);
      }

      const formattedImageContext = imageContext
        .keys()
        .reduce((array, path) => {
          return array.concat({
            path: imageContext(path),
            name: path.substring(path.lastIndexOf('/') + 1),
          });
        }, []);

      for (let image of formattedImageContext) {
        const collectionId = image.name.split('_')[0];
        if (Object.keys(currentCollections).indexOf(collectionId) != -1) {
          currentCollections[collectionId].push(image);
        }
      }

      imageCollections.value = currentCollections;
      imageCollectionsId.value = Object.keys(currentCollections)[0];
    }

    watch(currentImage, () => {
      if (!currentImage.value) return;
      convertImageToBase64(currentImage.value.path).then((data) => {
        currentImageFile.value = utils.base64ToFile(
          data,
          currentImage.value.name
        );
      });
    });

    onMounted(() => {
      if (props.noGallery) {
        templatePictureInput.value.click();
        window.addEventListener('focus', onWindowFocus, { once: true });
        window.document.addEventListener('mousemove', onWindowFocus, {
          once: true,
        });
      }
    });

    onUnmounted(() => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener('focus', onWindowFocus);
      window.document.removeEventListener('mousemove', onWindowFocus);
    });

    function onWindowFocus() {
      setTimeout(() => {
        if (currentImageFile.value == null) {
          if (props.noGallery) {
            context.emit('close');
          }
        }
      }, 1000);
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    async function importTemplatePictureInput(event) {
      const file = event.target.files[0];

      if (['image/png', 'image/jpeg'].indexOf(file['type']) == -1) {
        currentImageFile.value = null;
        currentImagePreview.value = null;
        showToastMessage(t('imageHandler.errors.fileFormatInvalid'));
        if (props.noGallery) context.emit('close');
        return;
      }

      if (file.size > MAX_SIZE_IN_BYTES) {
        currentImageFile.value = null;
        currentImagePreview.value = null;
        showToastMessage(t('imageHandler.errors.sizeExceeded'));
        if (props.noGallery) context.emit('close');
        return;
      }

      currentImageFile.value = file;
      currentImageMessage.value = null;
      const reader = new FileReader();
      reader.readAsDataURL(currentImageFile.value);
      reader.onload = async (e) => {
        currentImagePreview.value = e.target.result;
        // context.emit(
        //   'select',
        //   currentImageFile.value,
        //   currentImagePreview.value
        // );
        showCropping.value = true;
      };
    }

    function uploadImage() {
      context.emit('upload');
    }

    function selectImage() {
      context.emit('select', currentImageFile.value, currentImage.value.path);
    }

    async function selectCroppedImage() {
      const result = cropper.value.getResult();

      currentImageFile.value = utils.base64ToFile(
        result.canvas.toDataURL(result.image.type),
        currentImageFile.value.name
      );

      compressionLoading.value = true;

      currentImageFile.value = await imageCompression(currentImageFile.value, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      });

      compressionLoading.value = false;

      const reader = new FileReader();
      reader.readAsDataURL(currentImageFile.value);
      reader.onload = async (e) => {
        currentImagePreview.value = e.target.result;
        context.emit(
          'select',
          currentImageFile.value,
          currentImagePreview.value
        );
      };
    }

    function closeDisplay() {
      context.emit('close');
    }

    async function convertImageToBase64(imagePath) {
      try {
        const response = await fetch(imagePath);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Error converting image to Base64:', error);
        return null;
      }
    }

    return {
      showCropping,
      showCollectionPicker,
      closeDisplay,
      uploadImage,
      selectImage,
      selectCroppedImage,
      imageCollections,
      imageCollectionsId,
      currentImage,
      currentImageFile,
      currentImagePreview,
      importTemplatePictureInput,
      cropper,
      templatePictureInput,
      compressionLoading,
      scrollableDiv,
    };
  },
  components: {
    BasicSmModal,
    Cropper,
  },
};
</script>
