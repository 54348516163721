<template>
  <div class="flex justify-center">
    <div class="bg-white rounded-3xl border p-4">
      <qrcode-vue
        v-if="qrCodeData"
        :value="qrCodeData"
        :size="300"
        level="H"
        :margin="1"
      />
      <div
        v-else
        class="flex items-center justify-center h-[300px] w-[300px] bg-gray-50 rounded-lg"
      >
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="150"
          :width="150"
          :speed="1.5"
        />
      </div>
      <div class="flex justify-center items-center mt-5">
        <icon name="scan" class="w-4 fill-black mr-2" />
        <p class="font-bold">{{ $t('other.scanTxt') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { detect } from 'detect-browser';
import { toChecksumAddress } from 'ethereum-checksum-address';
import jwt from 'jsonwebtoken';

export default {
  props: { signup: Boolean },
  setup(props, context) {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const store = useStore();
    const route = useRoute();

    const qrCodeData = ref(null);

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);

    api
      .createAuthChallenge({ signup: props.signup, browser: detect().name })
      .then((res) => {
        const { challenge, dynamicLink } = res.data;
        qrCodeData.value = dynamicLink;

        api.waitChallengeValidation(challenge, (response) => {
          console.log(response);
          switch (response.status) {
            case 'expired':
              // display message
              break;
            case 'validated':
              const token = response.token;
              const issuerDid = jwt.decode(token).did;
              const currentIssuerAddress = utils.didToAddress(issuerDid);

              api.isIssuerRegistered(issuerDid).then((res) => {
                if (res.data.status) {
                  store.commit('setCurrentIssuerDid', issuerDid);
                  store.commit(
                    'setCurrentAddress',
                    toChecksumAddress(currentIssuerAddress)
                  );
                  store.commit(
                    'setCurrentPublicKeyBase58',
                    issuerDid.split(':')[2]
                  );
                  cookies.set('token', token);
                  context.emit('issuerConnected');
                  store.dispatch('getTemplateLists');
                  store.dispatch('getIssuerInfo');
                } else {
                  store.commit('resetIssuerState');
                  store.commit(
                    'setCurrentAddress',
                    toChecksumAddress(currentIssuerAddress)
                  );

                  store.commit(
                    'setCurrentPublicKeyBase58',
                    issuerDid.split(':')[2]
                  );
                  cookies.set('token_tmp', token);
                  store.commit('setIssuerConnectionDisplay', false);
                  context.emit('addressConnected');
                }
              });
              break;
          }
        });
      });

    return {
      route,
      currentIssuerDid,
      currentAddress,
      qrCodeData,
    };
  },
};
</script>
