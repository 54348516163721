<template>
  <div class="py-10 px-16">
    <p class="font-bold text-xl mb-10">
      {{ $t('settings.promote.title') }}
    </p>
    <p class="text-md text-gray-400 mb-10">
      {{ $t('settings.promote.infoTxt') }}
    </p>
    <p class="text-md font-bold mb-10">Email validation</p>
    <div v-if="emailVerification">
      <div class="flex gap-5">
        <p>Email verified :</p>
        <p class="font-bold">
          {{ emailVerification.value }} <icon name="wavy-check" />
        </p>
      </div>
    </div>
    <div v-else>
      <div class="flex gap-5">
        <div class="w-96">
          <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
            $t('settings.promote.emailPlaceholder')
          }}</span>
          <input
            type="text"
            :placeholder="$t('settings.promote.emailPlaceholderBis')"
            class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
            v-model="email"
          />
        </div>
        <button
          @click="verifyEmail"
          class="bg-gray-700 text-white shadow-sm font-medium rounded-lg px-7 py-2 cursor-pointer"
        >
          Verify
        </button>
      </div>
      <div v-if="showEmailVerifInput" class="flex items-center gap-5 mt-3">
        <p>Enter the code you received by email :</p>
        <div class="w-40">
          <input
            type="text"
            :placeholder="'Eg: F95MR1'"
            class="w-full outline-none border-gray-300 border rounded-lg px-3 py-2"
            v-model="emailCode"
          />
        </div>
        <button
          @click="validateEmail"
          class="bg-gray-700 text-white shadow-sm font-medium rounded-lg px-7 py-2 cursor-pointer"
        >
          Validate
        </button>
      </div>
    </div>

    <p class="text-md font-bold my-10">
      {{ $t('settings.promote.legalRepresentativeTxt') }}
    </p>
    <div class="flex gap-20">
      <div>
        <div class="w-96 mb-3">
          <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
            $t('settings.promote.firstNamePlaceholder')
          }}</span>
          <input
            type="text"
            :placeholder="$t('settings.promote.firstNamePlaceholderBis')"
            class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
            v-model="firstName"
          />
        </div>
        <div class="w-96 mb-3">
          <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
            $t('settings.promote.lastNamePlaceholder')
          }}</span>
          <input
            type="text"
            :placeholder="$t('settings.promote.lastNamePlaceholderBis')"
            class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
            v-model="lastName"
          />
        </div>
      </div>
      <div class="w-96 mb-1.5">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3">{{
          $t('settings.promote.reasonPlaceholder')
        }}</span>
        <textarea
          type="text"
          :placeholder="$t('settings.promote.reasonPlaceholderBis')"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          rows="4"
          v-model="reason"
        />
      </div>
    </div>
    <p class="text-md font-bold my-10">
      {{ $t('settings.promote.commitmentTxt') }}
    </p>
    <div class="w-[500px] flex mb-5 gap-2">
      <div class="-mt-0.5">
        <input
          type="checkbox"
          class="w-4 h-4 rounded-sm border-2 border-gray-300 outline-none cursor-pointer"
          v-model="commitment1"
        />
      </div>
      <p class="">
        {{ $t('settings.promote.commitment1Txt') }}
      </p>
    </div>
    <div class="w-[500px] flex mb-5 gap-2">
      <div class="-mt-0.5">
        <input
          type="checkbox"
          class="w-4 h-4 rounded-sm border-2 border-gray-300 outline-none cursor-pointer"
          v-model="commitment2"
        />
      </div>
      <p class="">
        {{ $t('settings.promote.commitment2Txt') }}
      </p>
    </div>
    <div class="w-[500px] flex mb-5 gap-2">
      <div class="-mt-0.5">
        <input
          type="checkbox"
          class="w-4 h-4 rounded-sm border-2 border-gray-300 outline-none cursor-pointer"
          v-model="commitment3"
        />
      </div>
      <p class="">
        {{ $t('settings.promote.commitment3Txt') }}
      </p>
    </div>
    <div class="w-full flex justify-end">
      <button
        @click="sendRequest"
        class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
      >
        {{ $t('settings.promote.sendButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const api = inject('api');

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );

    const firstName = ref(null);
    const lastName = ref(null);
    const email = ref(null);
    const reason = ref(null);
    const commitment1 = ref(null);
    const commitment2 = ref(null);
    const commitment3 = ref(null);

    const showEmailVerifInput = ref(false);
    const emailCode = ref('');

    const emailVerification = computed(() => {
      if (currentIssuerInfo.value && currentIssuerInfo.value.verifications) {
        return currentIssuerInfo.value.verifications.find(
          (verification) => verification.type == 'email'
        );
      }
      return null;
    });

    function sendRequest() {
      // check fields
      try {
        if (
          !firstName.value ||
          !lastName.value ||
          !email.value ||
          !reason.value
        )
          throw t('settings.promote.errors.fieldsMissing');

        if (firstName.value.length > 100)
          throw t('settings.promote.errors.firstNameTooLong');
        if (lastName.value.length > 100)
          throw t('settings.promote.errors.lastNameTooLong');
        if (reason.value.length > 1000)
          throw t('settings.promote.errors.reasonTooLong');

        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!emailRegex.test(email.value))
          throw t('settings.promote.errors.emailInvalid');

        if (!commitment1.value || !commitment2.value || !commitment3.value)
          throw t('settings.promote.errors.missingCommitment');
      } catch (err) {
        console.log(err);
        showToastMessage(err);
        return;
      }

      // send api call
      api
        .sendIssuerPromoteRequest({
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          reason: reason.value,
          profile: currentIssuerInfo.value.profile,
        })
        .then(() => {
          firstName.value = null;
          lastName.value = null;
          email.value = null;
          reason.value = null;
          commitment1.value = null;
          commitment2.value = null;
          commitment3.value = null;
        });
      createToast(t('settings.promote.requestSentConfirmation'), {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    function verifyEmail() {
      // check fields
      try {
        if (!email.value) throw t('settings.promote.errors.fieldsMissing');

        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!emailRegex.test(email.value))
          throw t('settings.promote.errors.emailInvalid');
      } catch (err) {
        console.log(err);
        showToastMessage(err);
        return;
      }

      // send api call
      api
        .createEmailVerification({
          email: email.value,
        })
        .then(() => {
          showEmailVerifInput.value = true;
        })
        .catch(() => {
          showToastMessage(
            `An error occured while creating email verification`
          );
        });
    }

    function validateEmail() {
      api
        .submitEmailValidation({
          email: email.value,
          code: emailCode.value,
        })
        .then(() => {
          showEmailVerifInput.value = false;
          store.dispatch('getIssuerInfo');
        })
        .catch(() => {
          showToastMessage(`Email can't be verified`);
        });
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    return {
      firstName,
      lastName,
      email,
      reason,
      commitment1,
      commitment2,
      commitment3,
      sendRequest,
      verifyEmail,
      currentIssuerInfo,
      showEmailVerifInput,
      emailCode,
      validateEmail,
      emailVerification,
    };
  },
};
</script>
